import { defu } from 'defu'
import type { FetchOptions } from 'ofetch'
import { useApiInterceptor } from './useApiInterceptor'
import type { UseFetchOptions } from '#app'
import type {
  PaymentIntent,
  User,
  Workspace,
  WorkspaceUser,
} from '~~/types/auth'
import type { UserRole } from '~~/types/settings'
import type * as API from '~~/types/api'
import { populateRelationships } from '~~/utils/api/v1'
import type {
  Document,
  DocumentPayload,
  Folder,
  FolderPayload,
  InvitationPayload,
  Sale,
  ViewData,
} from '~/types'

type ApiRoute = `/${string}`
type NoResponse = ''

export default () => {
  const config = useRuntimeConfig()
  const { language } = useLanguage()
  const { workspace } = useAuth()
  const { invalidAuthResponseInterceptor } = useApiInterceptor()

  const documentDefaultIncludes = computed(() => {
    const includes = [
      'folder.own_permissions',
      'owner',
      'tags',
      'contract.template',
      'parties.address',
      'terms_and_conditions',
      'string_values',
      'numeric_values',
      'date_values',
      'boolean_values',
      'attachments.file',
      'document_types.translations',
      'languages',
      'file',
      'retention',
    ]

    if (workspace.value?.attributes.summarisationEnabled)
      includes.push('summaries')

    return includes.join(',')
  })

  const request = <ResponseT> (url: string, options: UseFetchOptions<ResponseT> = {}) => {
    const { apiToken, activeWorkspaceId } = useAuth()

    const defaults: Partial<FetchOptions> = {
      baseURL: config.public.internalApiUrl,
      headers: {
        accept: 'application/json',
        authorization: apiToken.value ? `Bearer ${apiToken.value}` : '',
        ActiveAccount: activeWorkspaceId.value ?? '',
        'X-Language': language.value,
        'X-App-Version': config.public.appVersion,
      },
      onResponseError: invalidAuthResponseInterceptor,
    }

    return $fetch<ResponseT>(url, defu(options as object, defaults))
  }

  const requestWithBody = (method: 'PUT' | 'POST' | 'PATCH') =>
    <T, ReqBodyT = object>(path: string) =>
      (body: ReqBodyT, options: UseFetchOptions<T> = {}) =>
        request<T>(path, defu(options, { method, body }) as UseFetchOptions<T>)

  const putRequest = requestWithBody('PUT')
  const postRequest = requestWithBody('POST')

  const getRequest = <ResponseT>(path: ApiRoute) =>
    (options: UseFetchOptions<ResponseT> = {}) =>
      request<ResponseT>(path, options)

  const deleteRequest = <ResponseT = unknown>(path: ApiRoute) =>
    request<ResponseT>(path, { method: 'DELETE' })

  const me = getRequest<API.v1.Response<User>>('/me')

  const updateUser = (data: API.v1.ModelPartial<User>) =>
    putRequest<API.v1.Response<User>>('/me')({ data })

  const fetchActiveWorkspace = () => {
    const { activeWorkspaceId } = useAuth()
    return getRequest<API.v1.Response<Workspace>>(
      `/me/companies/${activeWorkspaceId.value}?include=role,partner,preferences,brandingLogo,businessProfile`,
    )().then(populateRelationships)
  }

  const fetchWorkspaceUsers = (opts?: { params?: Record<string, any> }) => {
    const { activeWorkspaceId } = useAuth()
    const params = defu(opts?.params, {})
    return getRequest<API.v1.Response<WorkspaceUser[]>>(
      `/me/companies/${activeWorkspaceId.value}/users`,
    )({ params }).then(populateRelationships)
  }

  const deleteWorkspaceUser = (id: WorkspaceUser['id']) => {
    const { activeWorkspaceId } = useAuth()
    return deleteRequest(`/me/companies/${activeWorkspaceId.value}/permissions/${id}`)
  }

  const updateWorkspaceUser = (
    updatePayload: Omit<API.v1.ModelPartial<WorkspaceUser>, 'type'>
      & { type?: WorkspaceUser['type'], attributes?: { permits?: string[] } },
  ) => {
    const { activeWorkspaceId } = useAuth()
    return putRequest(
      `/me/companies/${activeWorkspaceId.value}/permissions/${updatePayload.id}`,
    )({ data: updatePayload })
  }

  const setupIntent = () => postRequest<API.v1.Response<PaymentIntent>>('/me/setupIntents')({})

  const getSaleBySecret = (secret: string) => getRequest<{ data: Sale }>(`/v2/sales/${secret}`)
  const acceptSaleBySecret = (secret: string) =>
    postRequest<NoResponse>(`/v2/sales/${secret}/accept`)({})

  const fetchDocument = (id: string) => async (opts?: { params?: Record<string, any> }) => {
    const params = defu(opts?.params, { include: documentDefaultIncludes.value })

    return getRequest<API.v2.Response<Document>>(`/v2/documents/${id}`)({ params })
  }

  const fetchFolder = (id: string) => async (opts?: { params?: Record<string, any> }) => {
    const params = defu(
      opts?.params,
      { include: 'parent.own_permissions,breadcrumb,own_permissions' },
    )

    return getRequest<API.v2.Response<Folder>>(`/v2/folders/${id}`)({ params })
  }

  const fetchFolders = (parentId: string) => async (opts?: { params?: Record<string, any> }) => {
    const params = defu(
      { 'filter[parent_id]': parentId },
      opts?.params,
      {
        include: 'parent.own_permissions,breadcrumb,own_permissions',
        sort: 'name',
        per_page: 200,
        page: 1,
      },
    )
    return getRequest<API.v2.CollectionResponse<Folder>>('/v2/folders')({ params })
  }

  const fetchFoldersById = (ids: string | string[]) =>
    async (opts?: { params?: Record<string, any > }) => {
      const params = defu(
        { 'filter[id]': Array.isArray(ids) ? ids.join(',') : ids },
        opts?.params,
        { include: 'parent.own_permissions,breadcrumb,own_permissions', sort: 'name' },
      )
      return getRequest<API.v2.CollectionResponse<Folder>>('/v2/folders')({ params })
    }

  const resetContract = (contractId: string) =>
    postRequest(`/me/contract-builder/${contractId}/reset`)({})

  const restartSigningProcess = (contractId: string) =>
    postRequest(`/me/contract-builder/${contractId}/open`)({
      contractId,
    })

  const uploadDocument = async (doc: FormData) =>
    postRequest('/v2/documents')(doc as Record<string, any>)

  const updateFolder = (folder: FolderPayload) =>
    async (opts?: { params: Record<string, any> }) => {
      const params = defu(
        opts?.params,
        { include: 'parent.own_permissions,breadcrumb,own_permissions' },
      )

      return putRequest<API.v2.Response<Folder>>(`/v2/folders/${folder.id}`)(folder, { params })
    }

  const updateDocument = (document: DocumentPayload) =>
    async (opts?: { params: Record<string, any> }) => {
      const params = defu(opts?.params, { include: documentDefaultIncludes.value })

      return putRequest<API.v2.Response<Document>>(
        `/v2/documents/${document.id}`,
      )(document, { params })
    }

  const createDocumentCustomPropertyValue = (
    documentId: string,
    attributeId: string,
    payload: App.Data.Payloads.Documents.Properties.CreatePropertyPayload,
  ) => postRequest<API.v2.Response<
    App.Data.Document.Metadata.StringValueData |
    App.Data.Document.Metadata.NumericValueData |
    App.Data.Document.Metadata.DateValueData
  >>(`/v2/documents/${documentId}/properties/${attributeId}/values`)(payload)

  const updateDocumentCustomPropertyValue = (
    documentId: string,
    attributeId: string,
    valueId: string,
    payload: App.Data.Payloads.Documents.Properties.UpdatePropertyPayload,
  ) => putRequest<API.v2.Response<
    App.Data.Document.Metadata.StringValueData |
    App.Data.Document.Metadata.NumericValueData |
    App.Data.Document.Metadata.DateValueData
  >>(`/v2/documents/${documentId}/properties/${attributeId}/values/${valueId}`)(payload)

  const deleteDocumentCustomPropertyValue = (
    documentId: string,
    attributeId: string,
    valueId: string,
  ) => deleteRequest<API.v2.Response<
    App.Data.Document.Metadata.StringValueData |
    App.Data.Document.Metadata.NumericValueData |
    App.Data.Document.Metadata.DateValueData
  >>(`/v2/documents/${documentId}/properties/${attributeId}/values/${valueId}`)

  const createFolder = (folder: { parent_id: string, name: string }, workspaceId?: string) =>
    postRequest<API.v2.Response<Folder>>('/v2/folders')(
      folder,
      {
        params: { include: 'parent.own_permissions,breadcrumb,own_permissions' },
        headers: {
          ...(workspaceId ? { ActiveAccount: workspaceId } : {}),
        },
      },
    )

  const deleteFolder = (folderId: string) => deleteRequest(`/v2/folders/${folderId}`)

  const deleteDocument = (documentId: string) => deleteRequest(`/v2/documents/${documentId}`)

  const fetchAdminRole = async () => {
    const { activeWorkspaceId } = useAuth()
    const data = await getRequest<API.v1.Response<UserRole[]>>(
      `/me/companies/${activeWorkspaceId.value}/roles?limit=999`,
    )().then(r => r.data)

    return data.find(r => r.attributes.type === 'admin')
  }

  const sendWorkspaceInvitation = (payload: InvitationPayload) => {
    const { activeWorkspaceId } = useAuth()
    return postRequest(`/me/companies/${activeWorkspaceId.value}/invites`)({ data: payload })
  }

  const resendWorkspaceInvitation = (invitationId: string) => {
    const { activeWorkspaceId } = useAuth()
    return postRequest(
      `/me/companies/${activeWorkspaceId.value}/invites/${invitationId}/resend`,
    )({})
  }

  const revokeWorkspaceInvitation = (invitationId: string) => {
    const { activeWorkspaceId } = useAuth()
    return deleteRequest(`/me/companies/${activeWorkspaceId.value}/invites/${invitationId}`)
  }

  const search = async <T = any>(
    { workspaceId, ...params }: { query: string, 'filter[type]'?: string, workspaceId?: string },
  ) => {
    const headers = workspaceId ? { ActiveAccount: workspaceId } : {} as Record<string, string>
    return getRequest<API.v2.CollectionResponse<T>>('/v2/search')({ params, headers })
  }

  const searchDocuments = async (
    payload: App.Data.Payloads.Documents.Search.DocumentSearchPayload,
    opts?: { params: Record<string, any> },
  ) => {
    const params = defu(opts?.params, { include: 'folder.own_permissions,owner,contract,file,tags' })
    return postRequest<API.v2.CollectionResponse<Document>>(
      '/v2/documents/search',
    )(payload, { params })
  }

  const fetchViews = async (opts?: { params?: Record<string, any> }) => {
    const params = defu(opts?.params, { include: 'filters' })
    return getRequest<API.v2.CollectionResponse<ViewData>>('/v2/views')({ params })
  }

  const fetchView = async (viewId: string, opts?: { params?: Record<string, any> }) => {
    const params = defu(opts?.params, { include: 'filters' })
    return getRequest<API.v2.Response<ViewData>>(`/v2/views/${viewId}`)({ params })
  }

  const createView = async (
    payload: App.Data.Payloads.Views.ViewPayload,
    opts?: { params?: Record<string, any> },
  ) => {
    const params = defu(opts?.params, { include: 'filters' })
    return postRequest<API.v2.Response<ViewData>>('/v2/views')(payload, { params })
  }

  const updateView = async (
    viewId: string,
    payload: Partial<App.Data.Payloads.Views.ViewPayload>,
    opts?: { params?: Record<string, any> },
  ) => {
    const params = defu(opts?.params, { include: 'filters' })
    return putRequest<API.v2.Response<ViewData>>(`/v2/views/${viewId}`)(payload, { params })
  }

  const deleteView = async (viewId: string) => deleteRequest(`/v2/views/${viewId}`)

  return {
    me,
    fetchActiveWorkspace,
    fetchWorkspaceUsers,
    deleteWorkspaceUser,
    setupIntent,
    getSaleBySecret,
    acceptSaleBySecret,
    postRequest,
    getRequest,
    updateUser,
    fetchFolder,
    fetchFolders,
    fetchDocument,
    restartSigningProcess,
    resetContract,
    uploadDocument,
    updateFolder,
    updateDocument,
    createDocumentCustomPropertyValue,
    updateDocumentCustomPropertyValue,
    deleteDocumentCustomPropertyValue,
    createFolder,
    deleteFolder,
    deleteDocument,
    fetchAdminRole,
    sendWorkspaceInvitation,
    resendWorkspaceInvitation,
    revokeWorkspaceInvitation,
    updateWorkspaceUser,
    search,
    searchDocuments,
    fetchViews,
    fetchView,
    createView,
    updateView,
    deleteView,
    fetchFoldersById,
  }
}
